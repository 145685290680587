//
// Bootstrap Maxlength
//

.bootstrap-maxlength {
    z-index: 1040 !important;

    &.badge {
        display: inline-flex !important;
    }

    .modal & {
        z-index: ($zindex-modal + 5) !important;
    }
}
